export const constantNoMesin = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
];

export const memoBahanBakuConstant = [
  {
    mesin: "1",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "2",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "3",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "4",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "5",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "6",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "7",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "8",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "9",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "10",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "11",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "12",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "13",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "14",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "15",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "16",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "17",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "18",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "19",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "20",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "21",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "22",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "23",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "24",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "25",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "26",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "27",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "28",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "29",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "30",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "31",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "32",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "33",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "34",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "35",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "36",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "37",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
  {
    mesin: "38",
    customer: "",
    material: "",
    mold: "",
    color: "",
    total: 0,
    colorMaterial: "",
    moldType: "",
  },
];

export const constantProduksi = {
  shift: "",
  date: "",
  data: [
    {
      mesin: "1",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "2",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "3",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "4",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "5",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "6",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "7",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "8",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "9",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "10",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "11",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "12",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "13",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "14",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "15",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "16",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "17",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "18",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "19",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "20",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "21",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "22",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "23",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "24",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "25",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "26",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "27",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "28",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "29",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "30",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "31",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "32",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "33",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "34",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "35",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "36",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "37",
      downtime: 0,
      totalGumpalan: 0,
    },
    {
      mesin: "38",
      downtime: 0,
      totalGumpalan: 0,
    },
  ],
};

export const detailQc = [
  { cavity: 1, berat: "" },
  { cavity: 2, berat: "" },
  { cavity: 3, berat: "" },
  { cavity: 4, berat: "" },
  { cavity: 5, berat: "" },
  { cavity: 6, berat: "" },
  { cavity: 7, berat: "" },
  { cavity: 8, berat: "" },
  { cavity: 9, berat: "" },
  { cavity: 10, berat: "" },
  { cavity: 11, berat: "" },
  { cavity: 12, berat: "" },
  { cavity: 13, berat: "" },
  { cavity: 14, berat: "" },
  { cavity: 15, berat: "" },
  { cavity: 16, berat: "" },
  { cavity: 17, berat: "" },
  { cavity: 18, berat: "" },
  { cavity: 19, berat: "" },
  { cavity: 20, berat: "" },
  { cavity: 21, berat: "" },
  { cavity: 22, berat: "" },
  { cavity: 23, berat: "" },
  { cavity: 24, berat: "" },
];

export const pekerjaanPrinting = [
  { id: 1, jenisPekerjaan: "Print" },
  { id: 2, jenisPekerjaan: "Pasang Sticker" },
  { id: 3, jenisPekerjaan: "Punching" },
];
export const pekerjaanSP = [
  { id: 1, jenisPekerjaan: "Filling Plug" },
  { id: 2, jenisPekerjaan: "Pasang Handle" },
  { id: 3, jenisPekerjaan: "Pemasangan BNW" },
  { id: 4, jenisPekerjaan: "Pemasangan Valve & Filter" },
  { id: 5, jenisPekerjaan: "Putus Ronce" },
  { id: 6, jenisPekerjaan: "Sortir" },
  { id: 7, jenisPekerjaan: "Test Bocor" },
  { id: 8, jenisPekerjaan: "Ultrasonic" },
];

export const constWarnaPrinting = [
  "BLACK",
  "BLACK - ORANGE",
  "BLUE",
  "BLUE - RED",
  "GREEN",
  "GREEN - VIOLET",
  "NATURAL",
  "RED",
  "VIOLET",
  "VIOLET - PINK",
  "WHITE",
  "ORANGE",
];

export const constMesinSP = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

export const rejectSP = [
  { id: "1", desc: "Bergeser" },
  { id: "2", desc: "Bocor" },
  { id: "3", desc: "Cacat Kosmetik" },
  { id: "4", desc: "Deformasi" },
  { id: "5", desc: "Destruktif" },
  { id: "6", desc: "EX Bor" },
  { id: "7", desc: "Hangus" },
  { id: "8", desc: "Plate Ngambang" },
  { id: "9", desc: "Settingan" },
];
export const rejectPR = [
  { id: "1", desc: "Printing Meleber" },
  { id: "2", desc: "Printing Terkelupas" },
  { id: "3", desc: "Printing Miring" },
  { id: "4", desc: "Printing Geser" },
  { id: "5", desc: "Printing Tergores" },
  { id: "6", desc: "Printing Double" },
  { id: "7", desc: "Printing Tidak Sempurna" },
  { id: "8", desc: "Huruf Putus-Putus" },
  { id: "9", desc: "Huruf Mengecil" },
  { id: "10", desc: "Salah Printing" },
  { id: "11", desc: "Warna Printing" },
  { id: "12", desc: "Punching" },
];

export const shiftTim1 = ["SUPRIADI", "ACHMAD S", "SARIPUDIN"];

export const constCustomer = ["CBI", "GS", "MELCO", "TBP", "TIM", "YBI"];

export const jumlahHari = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export const bulan = [
  { name: "Januari", value: 1 },
  { name: "Februari", value: 2 },
  { name: "Maret", value: 3 },
  { name: "April", value: 4 },
  { name: "Mei", value: 5 },
  { name: "Juni", value: 6 },
  { name: "Juli", value: 7 },
  { name: "Agustus", value: 8 },
  { name: "September", value: 9 },
  { name: "Oktober", value: 10 },
  { name: "November", value: 11 },
  { name: "Desember", value: 12 },
];

export const barcodeSecurity = [
  "COOLING_TOWER_1",
  "COOLING_TOWER_2",
  "OFFICE_PRODUKSI",
  "RUANG_PRODUKSI",
  "GUDANG_TIMAH_CRUSHING",
  "GUDANG_BAHAN_BAKU",
  "PRINTING_FINISHING_GBJ",
  "Office Atas",
  "RUANG ATK",
  "BELAKANG_PABRIK",
];
